import { gql } from '@apollo/client';

export const LOGIN = gql`
    mutation ($input: LoginAdminDTO!) {
        login(input: $input) {
            admin {
                _id
                name
                email
                phone
                role
                status
                profileImage
            }
            refreshToken
            accessToken
            requiresOTPVerification
        }
    }
`;

export const REGISTER = gql`
    mutation ($input: CreateAdminDTO!) {
        register(input: $input) {
            _id
            name
            firstName
            lastName
            email
            role
            phone
            profileImage
        }
    }
`;

export const FORGOT_PASSWORD = gql`
    mutation ($input: ForgotPasswordDTO!) {
        forgotPassword(input: $input)
    }
`;

export const RESET_PASSWORD = gql`
    mutation ($input: ResetPasswordDTO!) {
        resetPassword(input: $input)
    }
`;

export const CLIENT_RESET_PASSWORD = gql`
    mutation resetPasswordClient($body: ResetPasswordDTO!) {
        resetPasswordClient(body: $body) {
            message
        }
    }
`;

export const CHANGE_PASSWORD = gql`
    mutation ($input: ChangePasswordDTO!) {
        changePassword(input: $input) {
            message
            status
        }
    }
`;

export const RESET_PASSWORD_OTP_VERIFICATION = gql`
    mutation ($input: ResetPasswordOtpVerificationDTO!) {
        resetPasswordOtpVerification(input: $input) {
            userId
            token
        }
    }
`;

export const RESEND_OTP_CODE = gql`
    mutation ($input: ForgotPasswordDTO!) {
        resendOtpCode(input: $input)
    }
`;

export const VERIFY_RESET_TOKEN = gql`
    mutation ($body: ResetTokenDTO!) {
        verifyResetToken(body: $body) {
            message
        }
    }
`;

export const VERIFY_LOGIN_OTP = gql`
    mutation VerifyLoginOtp($input: VerifyLoginOtpDTO!) {
        verifyLoginOtp(input: $input) {
            message
            admin {
                _id
                name
                firstName
                lastName
                email
                role
                status
                phone
                profileImage
            }
            accessToken
            refreshToken
            requiresOTPVerification
        }
    }
`;

export const RESEND_LOGIN_OTP = gql`
    mutation ResendLoginOTP($input: ResendLoginOTP!) {
        resendLoginOTP(input: $input) {
            message
        }
    }
`;
